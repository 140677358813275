<template>
  <iq-card body-class="p-0" class="iqCard">
    <div class="image_box">
      <el-image
        style="
          width: 100%;
          height: 156.5px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        "
        fit="cover"
        :src="cardInfo.image"
      >
      </el-image>
      <div class="imgTick" v-if="isShow">
        <el-tooltip class="item" placement="bottom-start">
          <div slot="content">
            <div style="padding-top:5px">Order Number:</div>
            <div
              v-for="(v, i) of cardInfo.order"
              :key="i"
              style="margin-top:10px;position:relative"
            >
              <i
                v-if="v.is_gift"
                style="position:absolute;left:-13px;top:0px"
                class="el-icon-present"
              ></i>
              <!-- <span v-else style="width:12px;display:inline-block">  </span> -->
              {{ v.order_no }}
            </div>
          </div>
          <div class="tickItem">
            <img
              src="../../../assets/images/event/ticketF.png"
              style="width:20px; height:20px"
              alt=""
            />
            ×
            <p>{{ cardInfo.order_total }}</p>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="userDetail">
      <div class="title-date">
        <h4 class="title" :title="cardInfo.title">{{ cardInfo.title }}</h4>
        <p class="date m-0">{{ cardInfo.time }}</p>
        <!-- <p v-if="isShow" class="order" :title="cardInfo.order_no">Order Number: {{ cardInfo.order_no }} </p> -->
      </div>
      <!-- <div v-if="cardInfo.avatar.length > 0" class="d-flex align-items-center mb-2">
        <div class="iq-media-group d-flex align-items-center">
          <a v-for="(v, i) in cardInfo.avatar.slice(0, 3)" :key="i" href="#" class="iq-media">
            <img class="img-fluid rounded-circle" :src="v.avatar" />
          </a>
          <div class="textDesc ml-2">
            <span style="color: #999"> {{ cardInfo.desc }}</span>
          </div>
        </div>
      </div> -->
    </div>
  </iq-card>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    cardInfo: {
      type: Object
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};
</script>
<style>
.el-tooltip__popper {
  padding: 5px 15px 10px 15px !important;
}
</style>
<style lang="scss" scoped>
.iqCard {
  /* width: 313px; */
  height: 260px;
  box-shadow: 0px 0px 20px 0px rgba(75, 75, 75, 0.4) !important;

  &:hover {
    box-shadow: 9.19px 9.19px 27.16px 0.39px rgba(75, 75, 75, 0.45) !important;
  }

  .image_box {
    position: relative;
    .imgTick {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #0097D9;
      width: 60px;
      height: 30px;
      line-height: 29px;
      padding: 0 3px;
      border-radius: 30px;

      .tickItem {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .userDetail {
    height: calc(100% - 155px);
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // 标题和日期
    .title {
      color: #333;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date {
      font-size: 14px;
      color: #999;
      padding-top: 18px;
      line-height: 1;
    }
    .order {
      color: #666;
      margin: 10px 0;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // 小头像
    .iq-media {
      width: 24px;
      height: 24px;
      margin-left: -12px !important;

      img {
        width: 100%;
      }
    }

    .iq-media-group .iq-media:first-child {
      margin-left: 0 !important;
    }
  }
}
</style>
